
.main-holder {
  min-height: 100vh;
  position: relative;

  &.dark {
    background: rgba(10,5,12,1);
    // background: linear-gradient(144deg, rgba(10,3,24,1) 0%, rgba(20,11,25,1) 100%);
    // background-color: black;
    // background: url('/backgrounds/gradienta-Mx688PpeE2A-unsplash-2.jpg');
    // background-position: top;
    background-size: cover;
    background-attachment: fixed;
  }
  .main-body {
    padding-bottom: 160px; /* Margin bottom by footer height */
    .footer {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 160px; /* Set the fixed height of the footer here */
      line-height: 40px; /* Vertically center the text there */
      /* background-color: #f5f5f5; */
    }
    
  }
}

:root {
  color-scheme: dark;
}