$danger: #eb4d4b;
$warning: #e7b64c;

$gray: #2f284fb9 !default;
$hedge-pink: #d62598 !default;
$hedge-purple: #9063cd !default;
$hedge-dark: #000000 !default;
$hedge-white: #e7e2fe !default;

// div {
//   letter-spacing: 0.1em;
// }

.card {
  background-color: rgba(42, 38, 48, 0.4);
  // background: linear-gradient(
  //   90deg,
  //   rgba(#5E4BBD, 0) 0%,
  //   rgba(#5E4BBD, 0.1) 35%,
  //   rgba(#5E4BBD, 0.1) 65%,
  //   rgba(#5E4BBD, 0) 100%
  // );
  backdrop-filter: blur(15px);
  // border: 1px solid fade-out($hedge-white, 0.9);
  transition: background-color 0.3s;
  .tight-card {
    border-radius: 0.5rem 0.5rem 0.2rem 0.2rem;
    .card-header:first-child {
      border-radius: 0.5rem 0.5rem 0 0;
    }
  }
  &.danger {
    background-color: rgba($danger, 0.15); // TODO FIX. Duplicated below
    border: 1px solid $danger; // TODO FIX. Duplicated below
  }
}

.blur-bg,
.bg-blur {
  background-color: rgba(255, 255, 255, 0.15);
  // background-clip: border-box;
  backdrop-filter: blur(15px);
  // border: 1px solid rgba(255, 255, 255, 0.125);
  transition: background-color 0.3s;
}

.hedge-table-row {
  background-color: rgba(255, 255, 255, 0.15);
  background-clip: border-box;
  backdrop-filter: blur(2px);
  border: 1px solid rgba(255, 255, 255, 0.325);
  border-radius: 20px;
}

.hedge-hover-row {
  transition: all 0.5s ease-out;
  &:hover {
    transition: all 0.2s ease-out;
    transform: scale(1.01);
    // background-color: fade-out($color: $hedge-white, $amount: 0.8);
    // border: 1px solid fade-out($color: $hedge-pink, $amount: 0.8);
    border: 1px solid fade-out($hedge-white, 0.9);
    // border-bottom: 1px solid fade-out($hedge-white, 0.9);
  }
  &.danger {
    border: 1px solid $danger;
    box-shadow: 0px 0px 10px 10px #eb4d4b22, inset 0px 0px 10px 10px rgba($danger, 0);
    background: repeating-linear-gradient(
      50deg,
      rgba(darken($danger, 50%), 0.7) 0px,
      rgba(darken($danger, 50%), 0.7) 10px,
      rgba(darken($danger, 55%), 0.7) 10px,
      rgba(darken($danger, 55%), 0.7) 20px
    );
  }
  &.warning {
    // border: 1px solid opacify($warning, 0.2);
    // background-color: rgba($warning, 0.15);
    // box-shadow: 0px 0px 10px 10px #eba64b22, inset 0px 0px 10px 10px rgba($warning, 0.0);
    background: repeating-linear-gradient(
      50deg,
      rgba(255, 255, 255, 0.15) 0px,
      rgba(255, 255, 255, 0.15) 10px,
      rgba(255, 255, 255, 0.18) 10px,
      rgba(255, 255, 255, 0.18) 20px
    );
  }
}

.home-coin-card {
  // background: $hedge-pink;
  border-radius: 10px;
  border: 1px solid $hedge-pink;
  box-shadow: 0px 0px 60px 20px fade-out($color: $hedge-pink, $amount: 0.8);
  transition: all 3s ease-in-out;
  // color: '#aaa';
  img {
    transform: scale(0.9);
    transition: all 3s ease-in-out;
  }
  &:hover {
    transition: all 1s ease-in-out;
    box-shadow: 0px 0px 70px 30px fade-out($color: $hedge-pink, $amount: 0.6);
    border-radius: 20px;
    img {
      transform: scale(1);
      transition: all 1s ease-in-out;
    }
  }
}

.docs {
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding-top: 30px;
    // margin-bottom: 20px;
  }
}

// .hedge-modal {
//   background-color: rgba(#000,0.15);
//   backdrop-filter: blur(2px);
//   .modal-content{
//     // background-color: rgba(#fff,0.85);
//     // backdrop-filter: blur(15px);
//     // border: 1px solid rgba(255,255,255, 0.125);
//     border-radius: 20px;
//   }

// }

.modal-content {
  background-color: rgba(255, 255, 255, 0.15);
  // background-clip: border-box;
  backdrop-filter: blur(15px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 20px;
}

.banner {
  background-color: #f5c242;
  padding: 2px;
}

form {
  input.form-control {
    background-color: rgba(#fff, 0.2);
    color: white;
    &:focus {
      background-color: rgba(#fff, 0.3);
      color: white;
    }
    &::placeholder {
      color: rgba(#fff, 0.4);
    }
  }
  .input-group-text {
    background-color: rgba(255, 255, 255, 0.2);
    color: white;
    font-weight: 800;
  }
  .input-group .btn {
    border: 1px solid #ced4da;
  }
}

.clickable {
  &:hover {
    cursor: pointer;
  }
}

.animated-bg {
  background: linear-gradient(-35deg, #05041ca6, #3b2d17a6, #1e3842a6, #153623a6);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.transition-height {
  transition-property: height;
  transition-duration: 3s;
}

.pointer {
  cursor: pointer;
}

.fa-theme-hedge {
  --fa-secondary-opacity: 0.9;
  --fa-primary-color: rgb(99, 102, 241);
  --fa-secondary-color: rgb(217, 70, 239);
}

// Home Page

.info-card {
  padding: 45px;
  background-color: black;
  border: 1px solid rgba($hedge-white, 0.3);
  border-radius: 7px;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  min-height: 320px;
  .body {
    transition: all 0.2s ease-in-out 0s;
    i {
      background-color: $hedge-pink;
      padding: 15px;
      border-radius: 5px;
    }
  }
  i.bg {
    position: absolute;
    font-size: 300px;
    background: none;
    width: auto;
    height: auto;
    color: fade-out($hedge-pink, 0.9);
    right: -60px;
    bottom: -80px;
    transition: all 0.2s ease-in-out 0s;
  }
  &:hover {
    background-color: fade-out($hedge-pink, 0.9);
    border-radius: 12px;
    .body {
      transform: translateY(-5px);
    }
    i.bg {
      font-size: 300px;
      // color: rgba(79, 63, 114, 0.25);
      transform: translateX(-15px) translateY(-15px);
    }
  }
}

.future-card {
  border: 1px solid rgba($hedge-white, 0.5);
  padding: 20px;
  border-radius: 7px;
  min-height: 255px;
  background-color: rgba($hedge-white, 0.1);
  transition: all 0.2s ease-in-out 0s;
  backdrop-filter: blur(15px);
  i {
    color: $hedge-pink;
  }
  &:hover {
    background-color: rgba($hedge-white, 0.2);
  }
}

.social-btn {
  // border: 1px solid $hedge-purple;
  border-radius: 5px;
  overflow: hidden;
  color: white;
  display: inline-flex;
  align-items: center;
  padding: 0;
  cursor: pointer;

  transition: all 0.3s ease-in-out;
  &.discord {
    background-color: #5165f6;
  }
  &.twitter {
    background-color: #1da1f2;
  }
  .icon-box {
    background-color: #212121;
    padding: 10px 17px;
    position: relative;
    i {
      transition: all 0.3s ease-in-out;
      // transform: rotate(360deg);
    }
    &:after {
      content: '';
      border: 10px solid;
      border-color: transparent transparent transparent #212121;
      position: absolute;
      top: 10px;
      right: -17px;
      transition: all 0.1s ease-in-out;
    }
  }
  .label {
    width: 180px;
    display: inline-block;
  }
  &:hover {
    .icon-box {
      background-color: #212121;
      i {
        transform: rotate(360deg);
      }
      // &:after {
      //   right: -48px;
      // }
    }
    &.discord {
      background-color: fade-out(#5165f6, 0.2);
    }
  }
}

.vault-row {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.bg-vault-danger {
  // border: 1px solid opacify($danger, 0.1);
  // box-shadow: 0px 0px 10px 10px #eb4d4b22,
  //   inset 0px 0px 10px 10px rgba($danger, 0);
  background: rgba(darken($danger, 15%), 0.2);
  // background: repeating-linear-gradient(
  //   50deg,
  //   rgba(darken($danger, 15%), 0.2) 0px,
  //   rgba(darken($danger, 15%), 0.2) 10px,
  //   rgba(darken($danger, 60%), 0.1) 10px,
  //   rgba(darken($danger, 60%), 0.1) 20px
  // );
}

.hedge-overview-bar {
  background: rgba(42, 38, 48, 0.4);
  // border-radius: 8px;
  .divider {
    opacity: 0.3;
    border-right: 1px solid #e0dede;
  }
}

.text-number {
  font-variant-numeric: tabular-nums;
  font-weight: 400;
}

.expand-drawer {
  margin-bottom: 30px;
  &:hover {
    transition: all 120ms ease-in-out;
    padding-top: 8px;
    margin-bottom: 22px;
  }
}

.balloon {
  display: inline-block;
  position: relative;
  // box-shadow: inset -10px -10px 0 rgba(0, 0, 0, 0.07);
  transition: transform 0.5s ease;
  animation: balloons 4s ease-in-out infinite;
}

@keyframes balloons {
  0%,
  100% {
    transform: translateY(0) rotate(0deg);
  }
  50% {
    transform: translateY(-25px) translateX(-10px) rotate(1deg);
  }
}


.pill-picker {
  display: flex;
  background-color: rgba(42, 38, 48, 0.4);
  padding: 4px;
  border-radius: 100px;
  .pill {
    padding: 5px 20px;
    border-radius: 100px;
    font-weight: bold;
    cursor: pointer;
    &.active {
      background: linear-gradient(130deg, rgba(214,37,152,1) 0%, rgba(144,99,205,1) 100%);
    }
  }
  &.pill-picker-small .pill {
    padding: 2px 10px;
    font-size: smaller;
  }
}

.card-well {
  background-color: rgba(black, 0.4);
  border-radius: 10px;
}