.loading-screen {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(24, 21, 32, 0.5);
  color: white;
  z-index: 10000000;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  // -webkit-backdrop-filter: blur(20px);
  // backdrop-filter: blur(0px);
  background-color: rgba(black,0.0);
  transition: all 0.5s linear;

  &.loading-transition-enter-done,
  &.loading-transition-enter-active {
    // backdrop-filter: blur(8px);
    background-color: rgba(black,0.75);
  }

  // .loading-screen-content{
  //   transform: translateY(-200px);

  //   .loading-transition-enter-done &,
  //   .loading-transition-enter-active & {
  //     transform: translateY(0px);
  //   }
  // }
  .card {
    opacity: 0;
    background-color: rgba(#39383A, 1);
    transform: translateY(100px) scale(0.7);
    transition: opacity 0.2s ease-in-out 0.3s, transform 0.2s ease-in-out 0.3s;
  }
  &.loading-transition-enter-done,
  &.loading-transition-enter-active {
    .card {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
  }

  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 70px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
}

$circle-length: 151px;
$check-length: 36px;

@keyframes scaleAnimation {
  0% {
    opacity: 0;
    transform: scale(1.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes drawCircle {
  0% {
    stroke-dashoffset: $circle-length;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes drawCheck {
  0% {
    stroke-dashoffset: $check-length;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#successAnimationCircle {
  stroke-dasharray: $circle-length $circle-length;
  stroke: #fff;
}

#successAnimationCheck {
  stroke-dasharray: $check-length $check-length;
  stroke: #fff;
}

#successAnimationResult {
  fill: #fff;
  opacity: 0;
}

#successAnimation.animated {
  animation: 1s ease-out 0s 1 both scaleAnimation;

  #successAnimationCircle {
    animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCircle,
      0.3s linear 0.9s 1 both fadeOut;
  }

  #successAnimationCheck {
    animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCheck,
      0.3s linear 0.9s 1 both fadeOut;
  }

  #successAnimationResult {
    animation: 0.3s linear 0.9s both fadeIn;
  }
}

#replay {
  background: rgba(255, 255, 255, 0.2);
  border: 0;
  border-radius: 3px;
  bottom: 100px;
  color: #fff;
  left: 50%;
  outline: 0;
  padding: 10px 30px;
  position: absolute;
  transform: translateX(-50%);
  &:active {
    background: rgba(255, 255, 255, 0.1);
  }
}
