
@import '../node_modules/@solana/wallet-adapter-react-ui/styles.css';

$hedge-pink:    #D62598 !default;
$hedge-purple:  #9063CD !default;
$hedge-dark:    #000000 !default;
$hedge-white:   #E7E2FE !default;

.wallet-adapter-modal-wrapper {
  background-color: #110F13;
  backdrop-filter: blur(4px);
  border: 1px solid rgba(255,255,255, 0.225);
  .wallet-adapter-button {
    background: $hedge-pink;
    color: #fff;
    border: 1px solid transparent;
  }
}

.wallet-adapter-button {
  background: #fff;
  color: #000;
  // border: 1px solid $hedge-pink;
}

.wallet-adapter-modal-list
.wallet-adapter-button {
  background: rgba(0,0,0, 0.225);
  color: #fff;
  &:not([disabled]):hover {
    background: rgba(0,0,0, 0.425);
  }
}

.wallet-adapter-button:not([disabled]):hover {
  background: $hedge-white;
  // border: 1px solid #ccc;
}