@import "../node_modules/bootstrap/scss/functions";
@import "./bootstrap-custom-vars";
@import "../node_modules/bootstrap/scss/mixins";

.homepage-preview-huge {
  font-weight: 600;
  font-size: 100px;
  line-height: 96.5%;
  /* identical to box height, or 339px */
  letter-spacing: 0.03em;
  @include media-breakpoint-up(lg) {
    font-size: 70px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 88px;
  }
  @include media-breakpoint-up(xxl) {
    font-size: 108px;
  }
}

.homepage-large-header {
  font-weight: 500;
  font-size: 260px;
  line-height: 220px;
  &.zero-percent {
    letter-spacing: 0;
  }
}
.homepage-lower-header {
  font-weight: 200;
  font-size: 60px;
  line-height: 40px;
  &.zero-percent {
    letter-spacing: 0;
  }
}

.homepage-stat {
  font-size: 50px;
  line-height: 55px;
  .sub-text {
    font-size: 13px;
    line-height: 13px;
  }
}
