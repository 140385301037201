@import "../node_modules/bootstrap/scss/functions";
@import "./bootstrap-custom-vars";
@import "../node_modules/bootstrap/scss/mixins";

.gradient-box {
  // display: flex;
  // align-items: center;
  // //width: 50vw;
  // width: 90%;
  // margin: auto;
  // max-width: 22em;

  position: relative;
  box-sizing: border-box;

  $border: 1px;
  color: #fff;
  background: #000;
  background-clip: padding-box; /* !important */
  border: solid $border transparent; /* !important */
  border-radius: 1em;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -$border; /* !important */
    border-radius: inherit; /* !important */
    background: linear-gradient(to bottom, fade-out(#D62598, 0), fade-out(#9063CD, 0));
  }
}
