.hedge-connections {
  .dropdown-menu {
    background-color: #39383A;
    backdrop-filter: blur(15px);
    border: 1px solid rgba(255, 255, 255, 0.125);
    transition: background-color 0.3s;
    border-radius: 10px;
    max-width: 300px;
    .dropdown-item {
      color: white;
      text-overflow: ellipsis;
      overflow: hidden;
      &.active {
        background-color: rgba(255, 255, 255, 0.15);
        font-weight: bold;
      }
      &:hover {
        background-color: rgba(255, 255, 255, 0.25);
      }
    }
    .dropdown-header {
      color: white;
      font-weight: 600;
      text-align: center;
    }
  }
}

.hedge-settings {
  .dropdown-toggle::after{
    display: none;
  }
  .dropdown-menu {
    background-color: #39383A;
    backdrop-filter: blur(15px);
    border: 1px solid rgba(255, 255, 255, 0.125);
    transition: background-color 0.3s;
    border-radius: 10px;
    max-width: 300px;
    .dropdown-item {
      color: white;
      text-overflow: ellipsis;
      overflow: hidden;
      &.active {
        background-color: rgba(255, 255, 255, 0.15);
        font-weight: bold;
      }
      &:hover {
        background-color: rgba(255, 255, 255, 0.25);
      }
    }
    .dropdown-header {
      color: white;
      font-weight: 600;
      text-align: center;
    }
  }
}