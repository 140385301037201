
.hedge-collateral-selector {
  // border: 1px solid rgba(255, 255, 255, 0.125);
  max-width: 150px;
  filter: grayscale(1);
  &:hover {
    filter: grayscale(0);
  }
  &:active {
    filter: grayscale(0);
  }
}

.hedge-collateral-selector-active {
  filter: grayscale(0);
}